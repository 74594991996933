import React,{useRef,useState,useEffect,useContext} from 'react'
import {UserContext} from '../context/UserContext'
import {Link} from 'react-router-dom'
import {Alert,Container,Row,Col,Form,Button,Table,Modal} from 'react-bootstrap'
//import FileUpload from './FileUpload'
import axios from 'axios'
import {FaBookOpen} from 'react-icons/fa'
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MultiSelect } from "react-multi-select-component";
import FileUpload from './FileUpload'
import CoachesVideosLang from './CoachesVideosLang'
import CoachesSelectVideo from './CoachesSelectVideo'
import ChallengeUsers from './ChallengeUsers'
import ReactPlayer from 'react-player'



export default function CoachesVideosForm(props) {

  const [record,setRecord]=useState({
    id: 0,
    IsActive:1,
    Descr:'',
    IsChallenge:0,
    Tags:[],
    VideoID:'',
    TagsStr:''
  })
  const [isLoading,setIsLoading]=useState(false)
  const [descr,setDescr]=useState('')
  const [sports,setSports]=useState([])
  const [tags,setTags]=useState([])
  const [allTags,setAllTags]=useState([])
  const [selected, setSelected] = useState([])
  const [show,setShow]=useState(false)
  
  const levels=[
    'Beginer','Intermediate','Advanced','Pro'
  ]

  const handleClose=()=>setShow(false)
  const handleShow=()=>setShow(true)

  useEffect(()=>{
    loadData(props.id)
    loadSports()
    loadVideoTags()
  },[props.id])


  useEffect(()=>{
   
    setTags(allTags.filter(item=>item.SportID==record.SportID))
  },[allTags,record.SportID])

  const loadData=(id)=>{
    setIsLoading(true)
    if (id!=0){
      axios.get(process.env.REACT_APP_API_SERVER+'admin/coaches/coachvideo/'+id,{
        headers: {
            'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
            }            
        })
      .then(res=>{
        //console.log(res.data.results) 
        setRecord(res.data.results)
        setDescr(res.data.results.Descr)
        setSelected(res.data.results.Tags.split(',').map(item=>{return {label:item,value:item}}))
        
        setIsLoading(false)
        
      }).catch(err=>{
          setIsLoading(false)

      })
    }else{
      setRecord({
        id: 0,
        IsActive:1,
        Descr:'',
        IsChallenge:0,
        Tags:[],
        VideoID:'',
        TagsStr:''
      })
      setIsLoading(false)
    } 
  }

  const loadSports=()=>{
    setIsLoading(true)
    axios.get(process.env.REACT_APP_API_SERVER+'admin/sports',{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
          }            
      })
    .then(res=>{
       // console.log(res.data.results) 
      setSports(res.data.results)
      setIsLoading(false)
      
    }).catch(err=>{
        setIsLoading(false)
        console.log(err)
    })          
  }


  const loadVideoTags=()=>{
    setIsLoading(true)
    //alert(SportID)
    axios.get(process.env.REACT_APP_API_SERVER+'admin/coaches/tags',{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
          }            
      })
    .then(res=>{
      //console.log(res.data.results) 
      setAllTags(res.data.results)
      setIsLoading(false)
      
    }).catch(err=>{
        setIsLoading(false)
        console.log(err)
    })          
  }


  useEffect(()=>{
    var TagsStr=''
    selected.forEach((item,index)=>{
      TagsStr=TagsStr + item.label
      if (index<selected.length-1){
        TagsStr=TagsStr +','
      }
    })
    setRecord({...record,Tags:selected,TagsStr})
    
  },[selected])


  const save=(e)=>{
    e.preventDefault()

    const obj={...record}

    axios.post(process.env.REACT_APP_API_SERVER+'admin/coaches',obj,{
      headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
          }            
      })
    .then(res=>{
      alert(res.data.msg)
      props.showForm(0,false)
    }).catch(err=>{
      alert('Υπήρξε ένα πρόβλημα, παρακαλώ προσπαθήστε ξανά')
    })
  }



const deleteRecord=()=>{
   if (window.confirm('ΔΙΑΓΡΑΦΗ ΟΛΟΥ ΤΟY VIDEO!!!! ΣΙΓΟΥΡΑ???')){
      axios.delete(process.env.REACT_APP_API_SERVER+'admin/coaches/'+props.id,{
          headers: {
              'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
              }            
          })
        .then(res=>{
          if (res.data.err==0){
              props.loadData()
             props.showForm(0,false)
          }else{
            alert(res.data.msg)
          }

          
      })
   }
}


const tagsList=tags.map(item=>{
  return(
    // <option key={item.id} value={item.id}>{item.Title}</option>
    {label:item.Title, value:item.Title}
  )
})

const sportsList=sports.map(item=>{
  return(
    <option key={item.SportID} value={item.SportID}>{item.SportName}</option>
  )
})

const levelsList=levels.map(item=>{
  return(
    <option key={item} value={item}>{item}</option>
  )
})


const sportSelected=(SportID)=>{
  setSelected([])
  setRecord({...record,SportID,Tags:[]})
  setTags(allTags.filter(item=>item.SportID==SportID))
}

  return (
    <>
    <br/><br/>
      {isLoading &&
      <Alert variant="warning">Loading...</Alert>
      
      }

 
    <Form onSubmit={save}>

          

            <Form.Group>
                <Form.Label><b>Άθλημα</b></Form.Label>  
                <Form.Control required as="select" value={record.SportID} onChange={(e)=>sportSelected(e.target.value)} style={{width:'200px'}}>
                    <option value=''>[Επιλέξτε Άθλημα]</option>
                    {sportsList}
                </Form.Control>
            </Form.Group>   

            


            <br/>
            <Form.Group controlId="formBasicEmail">
                <Form.Label><b>Τίτλος Video</b></Form.Label>
                <Form.Control type="text"  required value={record.Title} onChange={(e)=>setRecord({...record,Title:e.target.value})}/>
            </Form.Group> 

            <br/>
            <Form.Group>
            <Form.Label><b>Περιγραφή</b></Form.Label>
            <Form.Control as="textarea" rows={3} value={record.Descr} onChange={(e)=>setRecord({...record,Descr:e.target.value})}/>


            </Form.Group>

            <br/>
            <Form.Group>
                <Form.Label><b>Ενεργό</b></Form.Label>  
                <Form.Control as="select" value={record.IsActive} onChange={(e)=>setRecord({...record,IsActive:e.target.value})} style={{width:'200px'}}>
                    <option value='1'>ΝΑΙ</option>
                    <option value='0'>ΟΧΙ</option>
                </Form.Control>
            </Form.Group>

            <br/>
            <Form.Group  >
                <Form.Label><b>Tags</b></Form.Label>  


                <MultiSelect hasSelectAll={false} options={tagsList} value={selected} onChange={setSelected} labelledBy="Select"  />
            </Form.Group>   
            <br/>
            <Form.Group>
                <Form.Label><b>Level</b></Form.Label>  
                <Form.Control required as="select" value={record.Level} onChange={(e)=>setRecord({...record,Level:e.target.value})} style={{width:'200px'}}>
                    <option value=''>[Επιλέξτε Level]</option>
                    {levelsList}
                </Form.Control>

            </Form.Group>   

            <br/><br/>
            
              <p ><Button variant="success" onClick={()=>setShow(true)} >Προσθήκη/Αλλαγή Βίντεο</Button></p>
              {record.VideoID?.length>0 &&
                  <>
                    <h4>{record.VideoID}</h4>

                    <ReactPlayer 
                    url={`https://5f06d0924ebcd.streamlock.net:444/mlg/${record.VideoID}.smil/playlist.m3u8`}
                    controls={true}
                    light={process.env.REACT_APP_VIDEOSERVER_API+''+record.VideoID+'.jpg'}
                    width='300px'
                    height='190px'
                    />                 
                  
                  </>

              }
            <p align="right">

                {props.id!=0 &&
                <>
                <Button  variant="danger" style={{width:"150px"}} onClick={deleteRecord}>
                    Διαγραφή
                </Button> 
                &nbsp;&nbsp;   
                </>          
                }

                <Button  disabled={record.VideoID?.length==0} variant="dark" type="submit" style={{width:"150px"}} >
                    Αποθήκευση
                </Button>
            </p>
    </Form>

    {props.id!=0 && 
    <>
    <hr/>
    <CoachesVideosLang CoachVideoID={props.id}/>
    </>
    }



    <br/><br/><br/>


    <Modal show={show} onHide={handleClose} backdrop="static" size="lg">
        <Modal.Header closeButton>
        <Modal.Title>
            Προσθήκη/Αλλαγή Βίντεο
        </Modal.Title>
        </Modal.Header>
        
        <Modal.Body>
                <CoachesSelectVideo id={props.id} record={record} setRecord={setRecord} handleClose={handleClose}/>
        </Modal.Body>

    </Modal> 


    </>

  )
}
