import React,{useRef,useState,useEffect,useContext} from 'react'

import {Link} from 'react-router-dom'
import {Alert,Container,Row,Col,Form,Button,Table} from 'react-bootstrap'
import FileUpload from './FileUpload'
import axios from 'axios'
import ReactPlayer from 'react-player'
import {FaList} from 'react-icons/fa'

export default function ChallengeSelectVideo(props) {
    
    const [videos,setVideos]=useState([])
    const [videos1,setVideos1]=useState([])
    const [isLoading,setIsLoading]=useState(true)
    const [fn,setFn]=useState(0)
    const [newVideo,setNewVideo]=useState('')

    const sesrchStr=useRef()

    useEffect(()=>{
        loadVideos()
    },[])

    const loadVideos=()=>{
        setIsLoading(true)
        axios.get(process.env.REACT_APP_VIDEOSERVER_API+'upload/'+process.env.REACT_APP_NAME)
        .then(res=>{
            setVideos(res.data.sort((a,b)=>(a.Timestamp<b.Timestamp) ? 1 : -1))
            setVideos1(res.data.sort((a,b)=>(a.Timestamp<b.Timestamp) ? 1 : -1))
            setIsLoading(false)
        })
    }



    const searchVideos=()=>{
        var s=sesrchStr.current.value

        var videoList=[]
        if (s.length>0){
            videos.forEach(item=>{
                if (item.friendlyName){
                    if (item.id.indexOf(s)>-1 || item.friendlyName.indexOf(s)>-1){
                        videoList.push(item)
                    }  
                }
 
            })
        }else{
            videoList=[...videos1]
        }

        setVideos([...videoList].sort((a,b)=>(a.Timestamp<b.Timestamp) ? 1 : -1))
    }    


const selectVideo=(e,VideoID)=>{

    const obj={
        addVideo:e.target.checked,
        ChallengeID:props.ChallengeID,
        VideoID
    }
    axios.post(process.env.REACT_APP_API_SERVER+'admin/challenges/challengevideos',obj,{
        headers: {
            'Authorization': 'Bearer '+sessionStorage.getItem("apiToken")
            }            
        })
      .then(res=>{
        //alert(res.data.msg)
        //props.showForm(0,false)
        if (res.data.err==0){
            props.loadData()
            props.handleClose()
        }else{
            alert('Υπήρξε ένα πρόβλημα, παρακαλώ προσπαθήστε ξανά')
        }
      }).catch(err=>{
        alert('Υπήρξε ένα πρόβλημα, παρακαλώ προσπαθήστε ξανά')
      })
}


    const videosList=videos.map(item=>{
       if (item.id){
        var uplDate = new Date(parseInt(item.Timestamp))
        const itemDur=parseInt(item.duration)
        // console.log(item.Timestamp)
        // console.log(date)
        return(
            <tr key={item.id}>
                <td>
                    <input type="checkbox" onClick={(e)=>selectVideo(e,item.id)}/>

                    
                </td>

                <td>{item.friendlyName ? item.friendlyName : ''}</td>
                <td>

                    {parseInt(itemDur/60)}:{itemDur-parseInt(itemDur/60)*60}
                </td>
                <td>
                    <ReactPlayer 
                    url={`https://5f06d0924ebcd.streamlock.net:444/mlg/${item.id}.smil/playlist.m3u8`}
                    controls={true}
                    light={process.env.REACT_APP_VIDEOSERVER_API+''+item.id+'.jpg'}
                    width='300px'
                    height='190px'
                    />

                </td>
            </tr>
        )
       }
    })




    return (
        <>
            <Alert variant="secondary"><h4><FaList/> Videos</h4></Alert>
                <Container fluid>


                {isLoading &&
                    <h3>Loading, please wait....</h3>
                
                }

                {!isLoading &&
                <>
                <input onChange={searchVideos} type="text" className="form-control" ref={sesrchStr} placeholder="Search..."></input>
                <br/><br/>                

                <Table striped bordered hover>
                <thead>
                    <tr>
                    <th></th>
                    <th>Φιλική Ονομασία</th>
                    <th>Διάρκεια (min)</th>
                    
                    
                    <th></th>
                    </tr>
                </thead>
                <tbody>
                    {videosList}
                </tbody>
                </Table>
                </>
                }
                </Container>

               
        </>
        
    )
}
